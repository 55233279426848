<template>
    <div class="editor tw-flex tw-h-full">
        <div class="tw-h-full tw-relative tw-p-4 tw-bg-white tw-w-full tw-box-border">
            <div class="tw-overflow-y-auto" style="height: calc(100% - 2.5rem)">
                <div class="header tw-py-2 tw-font-semibold tw-text-lg tw-text-gray-600">Notes Editor</div>

                <div class="tw-flex tw-items-end tw-my-4">
                    <div class="tw-w-1/3">
                        <label class="tw-text-gray-500 tw-mb-1">Category name</label>
                        <vue-multi-select v-model="categoryName" :options="categoryNameOptions" :disabled="true" />
                    </div>

                    <div class="tw-w-1/3 tw-mx-4">
                        <label class="tw-text-gray-500 tw-mb-1">Section name</label>
                        <vue-multi-select v-model="sectionName" :options="sectionNameOptions" label="name" track-by="section_id" :disabled="true" />
                    </div>

                    <div class="tw-relative tw-flex tw-w-1/3 guidance-notes">
                        <div class="tw-w-10 tw-h-10 tw-rounded-full guidance-notes__icon tw-cursor-pointer tw-p-1 tw-border tw-border-solid tw-border-gray-200" :class="[!showGuidanceNotes ? 'tw-text-blue-600' : 'tw-text-gray-600', categoryName && sectionName ? '' : 'tw-pointer-events-none tw-opacity-50'].join(' ')" @click="showPopper">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </div>
                        <div class="tw-shadow-xl tw-rounded-sm guidance-notes__note-block tw-max-h-96 tw-overflow-y-auto" v-if="showGuidanceNotes">
                            <h1 class="tw-text-gray-700 tw-text-lg">Guidance notes</h1>
                            <template v-if="sectionName">
                                <p class="tw-text-gray-800 tw-text-sm" v-html="guidanceNotes"></p>
                            </template>
                            <template v-else>
                                <span>No guidance notes</span>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="tw-w-80 tw-relative tw-flex tw-right-2" style="left: calc(100% - 328px); top: 12px">
                    <font-awesome-icon icon="table" size="1x" @click="table.insertTable(3, 3)" class="tw-cursor-pointer tw-mt-3 tw-mr-4" />
                    <vue-multi-select v-model="activeTemplate" :options="templates" track-by="value" label="placeholder" @input="insertTemplate()" placeholder="insert template from here" :show-labels="false"></vue-multi-select>
                </div>
                <div class="qeditor-wrapper tw--mt-10 tw-overflow-y-auto">
                    <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)" />
                </div>

                <!-- Media Links Table -->
                <div class="options tw-border tw-mt-2 tw-border-solid tw-border-solid-gray-100 tw-flex tw-flex-col" style="height: calc(100% - 31rem)">
                    <div class="tw-flex tw-justify-between tw-bg-gray-100 tw-items-center">
                        <h4 class="tw-font-semibold tw-text-sm tw-mx-2">Media Links</h4>
                        <template v-if="noteKey === 'outputs'">
                            <!-- <neo-button class="more-info-btn tw-flex-shrink-0 tw-text-sm tw-h-10 tw-mr-2" label="More Info" color="var(--brand-color)" hoverBg="var(--brand-transparent)" hoverText="var(--brand-color)" padding="0.5rem 1rem" @click="$emit('more')"></neo-button> -->
                            <button class="tw-appearance-none tw-bg-transparent tw-px-4 tw-py-1 tw-h-10 tw-border tw-border-brand tw-border-solid tw-rounded-full tw-text-sm tw-outline-none tw-cursor-pointer tw-font-medium tw-text-brand hover:tw-text-white hover:tw-bg-brand hover:tw-border-white tw-mr-auto" @click="refreshData"><font-awesome-icon icon="sync" class="tw-mr-2" />Refresh</button>
                        </template>

                        <div class="tw-px-2">
                            <neo-input v-model.trim="searchFilter" class="tw-rounded-lg tw-h-12 tw-w-full" style="border: 1px solid #e8e8e8" placeholder="Search" bg="var(--brand-area-color)"> </neo-input>
                        </div>
                    </div>

                    <div class="urls tw-flex tw-overflow-y-auto tw-overflow-x-hidden tw-justify-between">
                        <neo-table :items="mediaLinks" :isHeader="true" :headers="headers" :item-key="itemKey" item-class="analysis-tools-results tw-justify-between" action-class="" :selectable="false" :searchFilter="searchFilter" filterKey="url" :hasAction="true" :loading="loaderController.mediaLinks.loading">
                            <template v-slot:body_url="{item, index}">
                                <div style="align-items: center">
                                    <div class="tw-self-center tw-gray-600 tw-max-w-lg">
                                        <a class="tw-no-underline tw-text-blue-600 tw-truncate tw-block hover:tw-text-blue-700 hover:tw-underline" :title="item.url" :href="item.url" target="_blank">{{ item.url }}</a>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:body_actions="{item, index}">
                                <div style="align-items: center">
                                    <div class="tw-flex tw-self-center tw-gray-600">
                                        <!-- <neo-button label="Copy" class="tw-w-40 tw-h-10 tw-rounded-lg" bg="var(--brand-color)" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" /> -->
                                        <neo-button @click="onLinkMoreInfoClick(item.url)" label="More info" padding="0.5rem 1rem" margin="0 5px" bg="#e1efff" hoverBg="white" color="var(--brand-color)" hoverText="var(--brand-color)" />
                                        <neo-button @click="insert(item.url)" label="Insert" padding="0.5rem 1rem" margin="0 5px" bg="#e1efff" hoverBg="white" color="var(--brand-color)" hoverText="var(--brand-color)" />
                                    </div>
                                </div>
                            </template>
                        </neo-table>
                    </div>
                </div>
            </div>
            <div class="tw-flex tw-justify-end tw-space-x-4 tw-mt-3 tw-right-2">
                <button class="tw-bg-gray-200 hover:tw-bg-gray-300 tw-text-black tw-py-2 tw-px-4 tw-rounded tw-border-none tw-cursor-pointer" @click="onCloseRichTextEditor()">
                    Close
                </button>
                <button class="tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-py-2 tw-px-4 tw-rounded tw-border-none tw-cursor-pointer" @click="handleSave">
                    Save
                </button>
            </div>
        </div>

        <div v-show="linkMoreShow" class="tw-h-full tw-bg-white tw-w-1/4 tw-p-2 tw-overflow-auto">
            <div class="tw-py-2 tw-font-semibold tw-text-sm">Research Notes</div>
            <div class="tw-text-sm tw-break-all" v-html="linkMoreInfo ? linkMoreInfo : 'No Research Notes found'" />
        </div>
    </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
// import Quill from "quill";
// import { quillEditor, Quill } from "vue-quill-editor";
import {quillEditor, Quill} from "@/lib/vue-quill-editor";
import {ImageExtend, QuillWatch} from "quill-image-extend-module";
import getActivePassiveMedia from "@/services/end-points/active-passive.data-provider";

import VueMultiselect from "@/components/vue-multiselect";
import {mapActions, mapGetters} from "vuex";
import Button from "@/components/button";
import axios from "@/axios";
import QuillBetterTable from "quill-better-table";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const Table = () => import("@/components/table");
const Input = () => import("@/components/input");

Quill.register("modules/ImageExtend", ImageExtend);

Quill.register(
    {
        "modules/better-table": QuillBetterTable,
    },
    true
);

export default {
    name: "NotesEditor",
    components: {
        "neo-button": Button,
        "neo-table": Table,
        "neo-input": Input,
        "font-awesome-icon": FontAwesomeIcon,
        quillEditor,
        "vue-multi-select": VueMultiselect,
    },
    props: ["msg", "card", "noteKey"],
    data() {
        return {
            activeTemplate: null,
            templates: [
                {type: "text", placeholder: "Template 1", content: "This is an empty template ____ with hello world _____"},
                // { type: "text", placeholder: "Template 1", content: "This ____ empty template ____ with hello world _____" },
            ],
            uploadedUrl: null,
            table: null,
            content: "",
            editorOption: {
                bounds: ".qeditor-wrapper",
                placeholder: "Enter notes here.",
                modules: {
                    // table: false,  // disable table module
                    "better-table": {
                        operationMenu: {
                            items: {
                                // unmergeCells: {
                                //   text: 'Another unmerge cells name'
                                // }
                            },
                            color: {
                                colors: ["#fff", "red", "#d5e1df", "#e3eaa7", " #b5e7a0", "#eea29a", "#80ced6", "#f7786b", "#f4e1d2"], // colors in operationMenu
                                text: "Background Colors", // subtitle
                            },
                        },
                    },

                    // see: https://github.com/NextBoy/quill-image-extend-module#quill-image-extend-module-%E7%9A%84%E6%89%80%E6%9C%89%E5%8F%AF%E9%85%8D%E7%BD%AE%E9%A1%B9
                    ImageExtend: {
                        loading: true,
                        name: "img",
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        headers: (xhr) => {
                            xhr.setRequestHeader("withCredentials", true);
                        },
                        response: (res) => {
                            return res.info;
                        },
                    },
                    // see: https://quilljs.com/docs/modules/toolbar/
                    toolbar: {
                        container: [[{size: ["small", false, "large", "huge"]}, {header: [1, 2, 3, 4, 5, 6, false]}], ["table"], ["bold", "italic", "underline", "strike", {color: []}, {background: []}], [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}], ["link", "image"], ["clean"]],
                        handlers: {
                            image: this.selectLocalImage,

                            // image: function() {
                            //   QuillWatch.emit(this.quill.id);
                            // }
                        },
                    },
                    keyboard: {
                        bindings: QuillBetterTable.keyboardBindings,
                    },
                },
            },
            onEditorBlur() {},
            onEditorFocus() {},
            onEditorReady(editor) {
                window.editor = editor;
                this.table = window.editor.getModule("better-table");
                //  this.insertBetterTableIConInToolBar()
                const content = this.card[this.noteKey];
                // const value = content
                // const delta = editor.clipboard.convert(value)

                // editor.setContents(delta, 'silent')
                editor.clipboard.dangerouslyPasteHTML(content, "silent");
            },
            itemKey: "url",
            headers: [
                {
                    text: "URL",
                    value: "url",
                },
            ],
            searchFilter: "",
            loaderController: {
                mediaLinks: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            // categoryName: null,
            // sectionName: null,
            linkMoreInfo: null,
            linkMoreShow: false,
            showGuidanceNotes: false,
        };
    },
    watch: {
        cachedProductsCategoriesMap: {
            handler(val) {
                console.log(val);
            },
            deep: true,
        },
    },
    async mounted() {
        await this.initProductCategories();
    },
    computed: {
        ...mapGetters(["getResearchData", "getCaseId", "getProduct", "getOutputTemplates"]),
        ...mapGetters("outputTemplates", ["cachedProductsCategoriesMap"]),
        mediaLinks() {
            return this.getResearchData.media.map((e) => ({style: "max-width:calc(100% - 140px);overflow-wrap:break-words;display:flex", url: e.url}));
        },
        editor() {
            return this.$refs.myQuillEditor;
        },
        categories() {
            return this.getOutputTemplates ?? [];
        },
        categoriesByNameMap() {
            const result = {};

            this.categories.forEach((category) => (result[category.category_name] = category));

            return result;
        },
        categoryNameOptions() {
            return this.categories?.map((_) => _.category_name) ?? [];
        },
        sectionNameOptions() {
            return this.categoriesByNameMap[this.categoryName]?.sections.filter((section) => section.active).map((section) => ({name: section.section_name, ...section})) ?? [];
        },
        guidanceNotes() {
            return this.sectionName?.guidance_notes !== "" ? this.sectionName?.guidance_notes : "Guidance Notes Not Available ";
        },
        categoryName() {
            return this.categories?.find((cat) => cat.category_id == this.card.output_category_id)?.category_name ?? null;
        },
        sectionName() {
            return this.sectionNameOptions?.find((sec) => sec.section_id === this.card.output_section_id) ?? null;
        },
    },
    methods: {
        ...mapActions("outputTemplates", ["getOutputTemplateProductCategories"]),
        onCloseRichTextEditor() {
            this.$emit("closeRichTextEditor");
        },
        insertTemplate() {
            this.insert(this.activeTemplate.content);
        },
        handleCategoryName(clear = false) {
            if (!clear) this.sectionName = null;
            this.showGuidanceNotes = false;
        },
        // insertBetterTableIConInToolBar(){
        //   const iconButton = document.querySelector(".ql-table")
        //   iconButton.addEventListener('click',(evt)=>{
        //     this.table.insertTable(3, 3)
        //   })
        // },
        fixTableStyle() {
            const tables = document.querySelectorAll(".quill-better-table");

            tables.forEach((tab) => {
                tab.querySelectorAll("td").forEach((td) => (td.style.border = "solid 1px black"));
                tab.style["border-collapse"] = "collapse";
            });
        },
        async selectLocalImage() {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.click();

            // Listen upload local image and save to server
            input.onchange = async () => {
                const file = input.files[0];

                // file type is only image.
                if (/^image\//.test(file.type)) {
                    await this.saveToServer(file);
                } else {
                    console.warn("You could only upload images.");
                }
            };
        },

        /**
         * Step2. save to server
         *
         * @param {File} file
         */
        async saveToServer(file) {
            const fd = new FormData();
            fd.append("file", file);

            try {
                const url = `/uploads/reports/images`;
                const res = await axios.post(url, fd, {
                    headers: {"Content-Type": "multipart/form-data"},
                });
                this.uploadedUrl = `${process.env.VUE_APP_IMAGES_URL}/${res.data.file}`;
                this.insertToEditor();
            } catch (error) {}
        },

        /**
         * Insert image url to rich editor.
         *
         * @param {string} url
         */
        insertToEditor(url) {
            // push image url to rich editor.

            const range = window.editor.getSelection();
            window.editor.editor.insertEmbed(range.index, "image", this.uploadedUrl);
        },

        handleSave() {
            this.fixTableStyle();
            // return
            const content = window.editor.getSemanticHTML();
            this.card[this.noteKey] = content;
            this.$emit("notesChanged", content);
            // this.$modal.hide("ck-editor-modal");
        },

        customLabel({title, desc}) {
            return `${title} – ${desc}`;
        },
        insert(link) {
            var selection = window.editor.getSelection(true);
            if (selection.length >= 1) {
                var text = window.editor.getText(selection.index, selection.length);
                window.editor.format("link", link);
            } else {
                window.editor.insertText(selection.index, link);
            }
            this.texts.forEach((e) => {
                // var selection = window.editor.getSelection(true);
            });
        },
        refreshData() {
            // TODO
            console.log("refresh links");
        },
        setLinkMoreInfo(text) {
            this.linkMoreShow = true;
            if (typeof text !== "string") throw new TypeError("text must be a string instead of: ", text);
            this.linkMoreInfo = text;
        },
        async onLinkMoreInfoClick(url) {
            const researchNotes = await getActivePassiveMedia(this.getCaseId);
            const matchedNote = researchNotes?.find((note) => note.url === url);

            if (!matchedNote) {
                this.$toast.warning("there is no research note for media link");
                return;
            }

            this.setLinkMoreInfo(matchedNote.notes);
        },
        initProductCategories() {
            const componentInstance = this;

            return new Promise((res) => {
                const unwatchGetProduct = componentInstance.$watch(
                    "getProduct",
                    async (product) => {
                        if (product.key) {
                            await componentInstance.getOutputTemplateProductCategories(product.key);
                            unwatchGetProduct();
                            res();
                        }
                    },
                    {immediate: true}
                );
            });
        },
        showPopper() {
            this.showGuidanceNotes = !this.showGuidanceNotes;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../mixins.scss";
* {
    @include scrollBar;
}
.qlbt-operation-menu {
    z-index: 33;
}
.scale-140 {
    transform: scale(1.4);
}
</style>
<style lang="scss">
.qeditor-wrapper {
    height: 22rem;
}
.ql-toolbar.ql-snow {
    padding-right: 330px;
}
</style>

<style lang="scss" scoped>
.guidance-notes {
    &__icon {
        cursor: pointer;
    }

    &__note-block {
        // display: none;
        position: absolute;
        padding: 15px;
        z-index: 1;
        background-color: #fff;
        border-radius: 6px;
        width: 75%;
        border: 1px solid #ddd;
        // box-shadow: 0 0 10px #00000071;
        top: 42px;

        &:hover {
            display: block;
        }
    }

    &__icon:hover + &__note-block {
        display: block;
    }
}
</style>
